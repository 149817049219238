import { Head } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'

const AppHead = ({ title, children }: any) => {
    const { appName } = usePage()?.props
    const appTitle = appName || ''
    return (
        <Head>
            <title>{title ? `${title} - ${appTitle}` : `${appTitle}`}</title>
            {children}
        </Head>
    )
}

export default AppHead
